<template>
  <div>
    <ErrorPage v-if="showError" />
    <div v-else>
      <loader v-if="isLoading" size="xxs" :loader-image="false" />
      <div v-else class="flex flex-col gap-4 w-full">
        <c-card class="w-full flex flex-col p-5">
          <div class="w-full gap-5 mb-6 flex">
            <c-card class="flex justify-between gap-4 py-6 px-4">
              <c-card class="flex justify-center items-center p-2">
                <c-icon class-name="text-jet" icon-name="icon-union" size="s" />
              </c-card>
              <div class="flex items-center gap-4 divide-x divide-romanSilver">
                <div class="flex flex-col">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Pending Requests</span
                  >
                  <span class="text-lg font-extrabold text-carrotOrange">{{
                    summary.pendingCount || 0
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Approved Requests</span
                  >
                  <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                    summary.approvedCount || 0
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Disapproved Requests</span
                  >
                  <span class="text-lg font-extrabold text-desire">{{
                    summary.disapprovedCount || 0
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Cancelled Requests</span
                  >
                  <span class="text-lg font-extrabold text-desire">{{
                    summary.cancelledCount || 0
                  }}</span>
                </div>
              </div>
            </c-card>
          </div>
          <template v-slot:footer>
            <CardFooter
              printcard
              reloadcard
              search-placeholder="Search by leave type or details"
              @reload="mode === 'admin' ? getDaysBackRequest() : getManagersDayBackRequest()"
              @actionModal="isOpenFilter = true"
              @sortType="handleSearch($event, 'sort')"
              @searchResult="handleSearch($event, 'search')"
            />
          </template>
        </c-card>

        <c-table
          :headers="headers"
          :items="employees"
          aria-label="days back table"
          :loading="isFetching"
          :has-number="false"
          :page-sync="true"
          :pagination-list="pagination"
          @page="$queryBuilder({ page: $event }, getDaysBackRequest)"
          @itemsPerPage="$queryBuilder({ perPage: $event }, getDaysBackRequest)"
          v-if="employees.length || isFetching"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.fname" class="flex justify-start items-start gap-2">
              <div>
                <span
                  v-if="item.data.photo"
                  class="flex justify-center items-center w-9 h-9"
                >
                  <img
                    :src="item.data.photo"
                    class="w-9 h-9 rounded"
                    alt="profile photo"
                  />
                </span>
                <div
                  v-else
                  class="flex justify-center items-center w-9 h-9 rounded border"
                >
                  <span
                    class="text-blueCrayola text-center font-semibold text-lg p-2"
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-0">
                <span class="font-semibold text-darkPurple text-base">
                  {{ item.data.fname }} {{ item.data.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                  {{
                    item.data.userDesignation && item.data.userDesignation.name
                  }}
                </span>
              </div>
            </div>
            <span
              v-if="item.category"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.category.name }}
            </span>
            <span
              v-if="item.requestReason"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.requestReason }}
            </span>
            <div
              v-if="item.requestStartDate"
              class="flex flex-col justify-start gap-1"
            >
              <div
                class="text-darkPurple text-sm font-semibold whitespace-nowrap"
              >
                {{
                  $DATEFORMAT(
                    new Date(item.data.requestStartDate),
                    "MMMM dd, yyyy"
                  )
                }}
                to<br />
                {{
                  $DATEFORMAT(
                    new Date(item.data.requestEndDate),
                    "MMMM dd, yyyy"
                  )
                }}
              </div>
              <span class="text-carrotOrange text-sm">
                {{ item.data.leaveRequest.daysRequested }} Day(s)
              </span>
            </div>
            <span
              v-if="item.daysRequested"
              class="text-darkPurple text-sm font-normal"
            >
              {{ item.data.daysBackRequested }} Day(s)
            </span>
            <div
              v-if="item.approvalStatus"
              class="rounded-md p-2 text-sm w-28 text-center capitalize"
              :class="{
                'bg-green-100 text-mediumSeaGreen':
                  item.data.approvalStatus === 'approved',
                'bg-yellow-100 text-carrotOrange':
                  item.data.approvalStatus === 'pending',
                'bg-red-100 text-flame text-sm':
                  item.data.approvalStatus === 'disapproved',
                'bg-red-100 text-flame':
                  item.data.approvalStatus === 'cancelled',
                'bg-gray-100 text-romanSilver':
                  item.data.approvalStatus === 'draft',
              }"
            >
              {{ item.data.approvalStatus }}
            </div>

            <div v-if="item.requestId">
              <c-menu left @click.stop="">
                <template v-slot:title>
                  <c-icon icon-name="more_icon" size="xs" />
                </template>
                <div
                  class="flex flex-col w-48 h-18 p-2 justify-start items-start"
                >
                  <div
                    class="w-full"
                    @click="checkPrivileges('view details', item.data)"
                  >
                    <div class="hover:bg-ghostWhite w-full rounded-md">
                      <div
                        class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                      >
                        <c-icon
                          icon-name="icon-eye"
                          class-name="text-darkPurple"
                          size="xs"
                        />
                        <span class="text-darkPurple text-sm"
                          >View Details</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-full"
                    @click="
                      checkPrivileges('view approval', item.data.requestId)
                    "
                  >
                    <div class="hover:bg-ghostWhite w-full rounded-md">
                      <div
                        class="flex justify-start items-center p-2 gap-2 cursor-pointer"
                      >
                        <c-icon
                          icon-name="icon-upgrade"
                          class-name="text-blueCrayola"
                          size="xs"
                        />
                        <span class="text-darkPurple text-sm"
                          >Approval Status</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </c-menu>
            </div>
          </template>
        </c-table>

        <div
          v-else
          class="flex flex-col justify-center items-center gap-0 px-10"
        >
          <Icon
            icon-name="paygrade_Illustration"
            size=""
            class-name="w-56 h-56"
          />
          <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
            Days back requests can be found here. This is empty now, but you can
            start adding days back requests to populate this area.
          </p>
        </div>

        <FilterTable
          v-if="isOpenFilter"
          :filter-data="filterOptions"
          :show-search="false"
          @close="isOpenFilter = false"
          @applyFilter="onSubmitFilter($event)"
        />

        <ViewDaysBackRequest ref="viewDaysBackRequestRef" />
        <AddLeaveRequest ref="leaveRequestRef" />
        <ViewLeaveRequest ref="viewRequestRef" />
        <GetLeaveBackRequest ref="getLeaveBackRef" />
        <ApprovalStatus ref="approvalStatusRef" :mode="mode" />
      </div>
    </div>
  </div>
</template>

<script>
import { differenceInDays } from "date-fns";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CIcon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ApprovalStatus from "@/components/ApprovalStatus";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    CCard,
    CTable,
    CIcon,
    CMenu,
    CardFooter,
    FilterTable,
    ApprovalStatus,
    ErrorPage,
    ViewDaysBackRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/ViewDaysBackRequest"
      ),
    AddLeaveRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/AddLeaveRequest"
      ),
    ViewLeaveRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/ViewLeaveRequest"
      ),
    GetLeaveBackRequest: () =>
      import(
        "../../modules/Admin/BenefitsModule/Leave/sideActions/GetLeaveBack"
      ),
  },
  data() {
    return {
      differenceInDays,
      showError: false,
      isOpenFilter: false,
      isLoading: false,
      isFetching: false,
      setQueryTimeout: null,
      queryParams: {},
      employees: [],
      summary: {},
      pagination: {},
      headers: [
        { title: "Employee Name", value: "fname" },
        { title: "Leave Type", value: "category" },
        { title: "Details", value: "requestReason" },
        { title: "Approved Duration", value: "requestStartDate" },
        { title: "Days Back Request", value: "daysRequested" },
        { title: "status", value: "approvalStatus" },
        { title: "", value: "requestId", image: true },
      ],
      filterOptions: [
        {
          header: "Status",
          value: "status",
          optionsBox: [
            { name: "Pending", id: "pending" },
            { name: "Approved", id: "approved" },
            { name: "Disapproved", id: "disapproved" },
            { name: "Cancelled", id: "cancelled" },
          ],
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  methods: {
    handleSearch(event, objType) {
      const url = this.mode === "admin" ? this.getDaysBackRequest() : this.getManagersDayBackRequest()
      const queryObject = {};
      queryObject[objType] = event;
      this.$queryBuilder(queryObject, url)
    },
    async checkPrivileges(slug, data) {
      switch (slug) {
        case "view details":
            if (this.mode === 'admin'){
              try {
                await this.$handlePrivilege("daysBackRequest", "viewDetails");
                this.$refs.viewDaysBackRequestRef.onOpen(data);
              } catch (error) {
                this.$toasted.error(
                  "You do not have permission to perform this task",
                  {
                    duration: 5000,
                  }
                );
              }
            }
            else {
              this.$refs.viewDaysBackRequestRef.onOpen(data);
            }
          break;

        case "view approval":
            if (this.mode === 'admin'){
              try {
                await this.$handlePrivilege(
                  "daysBackRequest",
                  "viewApprovalStatus"
                );
                this.$refs.approvalStatusRef.toggle(data);
              } catch (error) {
                this.$toasted.error(
                  "You do not have permission to perform this task",
                  {
                    duration: 5000,
                  }
                );
              }
            }
            else{
                this.$refs.approvalStatusRef.toggle(data);
            }
          break;
        default:
          break;
      }
    },
    onSubmitFilter(options) {
      this.$queryBuilder(options, this.getDaysBackRequest);
      this.isOpenFilter = false;
    },

    async getDaysBackRequest(params) {
      this.isFetching = true;

      await this.$_getAdminLeaveBackRequest(params).then(({ data }) => {
        this.pagination = data.meta;
        this.summary = data.summary;
        this.employees = data.employees.map((item) => ({
          ...item,
          category: item.leaveRequest.category,
          requestReason: item.leaveRequest.requestReason,
          requestStartDate: item.leaveRequest.requestStartDate,
          requestEndDate: item.leaveRequest.requestEndDate,
          daysRequested: item.leaveRequest.daysRequested,
        }));

        this.isFetching = false;
      });
    },

    async getManagersDayBackRequest(params) {
      this.isFetching = true;

      await this.$_getManagersEmployeesDayBack(params).then(({ data }) => {
        this.pagination = data.meta;
        this.employees = data.employees.map((item) => ({
          ...item,
          category: item.leaveRequest.category,
          requestReason: item.leaveRequest.requestReason,
          requestStartDate: item.leaveRequest.requestStartDate,
          requestEndDate: item.leaveRequest.requestEndDate,
          daysRequested: item.leaveRequest.daysRequested,
        }));

        this.isFetching = false;
      });
    },

    async getManagersSummary() {
      await this.$_getManagersDayBackSummary().then((response) => {
        this.summary = response.data.summary;
        this.isLoading = false;
      });
    },

    async bootstrapModule() {
      if (this.mode === "admin") {
      this.isLoading = true;
        try {
          await this.$handlePrivilege("daysBackRequest", "viewRegister");
          await this.getDaysBackRequest();
          this.showError = false;
          this.isLoading = false;
        } catch (error) {
          this.showError = true;
          this.isLoading = false;
        }
      } else {
        await this.getManagersDayBackRequest();
        await this.getManagersSummary();
      }
    },
  },
  async created() {
      await this.bootstrapModule();
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
